$md-salmon: (
  50: #fff1ef,
  100: #fedcd6,
  200: #fec4bb,
  300: #feaca0,
  400: #fd9b8b,
  500: #fd8977,
  600: #fd816f,
  700: #fc7664,
  800: #fc6c5a,
  900: #fc5947,
  A100: #ffffff,
  A200: #ffffff,
  A400: #ffeceb,
  A700: #ffd5d1,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-dark: (
  50: #e5e5e7,
  100: #bebfc3,
  200: #93959b,
  300: #676a72,
  400: #474a54,
  500: #262a36,
  600: #222530,
  700: #1c1f29,
  800: #171922,
  900: #0d0f16,
  A100: #5a76ff,
  A200: #274bff,
  A400: #0029f3,
  A700: #0024da,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #ffffff,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-noon: (
  50: #f3f9fc,
  100: #e2f0f8,
  200: #cfe6f3,
  300: #bbdcee,
  400: #add5ea,
  500: #9ecde6,
  600: #96c8e3,
  700: #8cc1df,
  800: #82badb,
  900: #70aed5,
  A100: #ffffff,
  A200: #ffffff,
  A400: #e9f6ff,
  A700: #cfecff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-turquoise: (
  50: #eafbfa,
  100: #caf5f2,
  200: #a7efea,
  300: #83e9e2,
  400: #69e4db,
  500: #4edfd5,
  600: #47dbd0,
  700: #3dd7ca,
  800: #35d2c4,
  900: #25caba,
  A100: #ffffff,
  A200: #d3fffa,
  A400: #a0fff5,
  A700: #86fff2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #000000,
    900: #000000,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!

@include mat-core();

$text: mat-palette($md-noon);
$primary: mat-palette($md-salmon);
$secondary: mat-palette($md-turquoise);
$dark: mat-palette($md-dark);

// The "warn" palette is optional and defaults to red if not specified.
$app-theme: mat-light-theme($text, $primary);

@include angular-material-theme($app-theme);

// or any global you wish to share with components
:root {
  --text-100: #{mat-color($text, 100)};
  --text-200: #{mat-color($text, 200)};
  --text-300: #{mat-color($text, 300)};
  --text-400: #{mat-color($text, 400)};
  --text: #{mat-color($text, 500)};
  --text-600: #{mat-color($text, 600)};
  --text-700: #{mat-color($text, 700)};
  --text-800: #{mat-color($text, 800)};
  --text-900: #{mat-color($text, 900)};
  --text-contrast: #{mat-color($text, default-contrast)};

  --primary-100: #{mat-color($primary, 100)};
  --primary-200: #{mat-color($primary, 200)};
  --primary-300: #{mat-color($primary, 300)};
  --primary-400: #{mat-color($primary, 400)};
  --primary: #{mat-color($primary, 500)};
  --primary-600: #{mat-color($primary, 600)};
  --primary-700: #{mat-color($primary, 700)};
  --primary-800: #{mat-color($primary, 800)};
  --primary-900: #{mat-color($primary, 900)};
  --primary-contrast: #{mat-color($primary, default-contrast)};

  --secondary-100: #{mat-color($secondary, 100)};
  --secondary-200: #{mat-color($secondary, 200)};
  --secondary-300: #{mat-color($secondary, 300)};
  --secondary-400: #{mat-color($secondary, 400)};
  --secondary: #{mat-color($secondary, 500)};
  --secondary-600: #{mat-color($secondary, 600)};
  --secondary-700: #{mat-color($secondary, 700)};
  --secondary-800: #{mat-color($secondary, 800)};
  --secondary-900: #{mat-color($secondary, 900)};
  --secondary-contrast: #{mat-color($secondary, default-contrast)};

  --dark-100: #{mat-color($dark, 100)};
  --dark-200: #{mat-color($dark, 200)};
  --dark-300: #{mat-color($dark, 300)};
  --dark-400: #{mat-color($dark, 400)};
  --dark: #{mat-color($dark, 500)};
  --dark-600: #{mat-color($dark, 600)};
  --dark-700: #{mat-color($dark, 700)};
  --dark-800: #{mat-color($dark, 800)};
  --dark-900: #{mat-color($dark, 900)};
  --dark-contrast: #{mat-color($dark, default-contrast)};
}

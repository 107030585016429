.timeline-event-card-container {
  width: calc(100% - 60px);
  padding: 20px 15px;

  .timeline-event-card {
    border-radius: 5px;
    background: linear-gradient(145deg, var(--dark-600), var(--dark-800));
    box-shadow: 6px 6px 12px var(--dark-800), -6px -6px 12px var(--dark-600);
    justify-content: space-between;

    &:hover {
      background: var(--dark-800);
      box-shadow: 6px 6px 12px var(--dark-800), -6px -6px 12px var(--dark-700);
    }

    .timeline-card-content {
      width: 100%;
      padding: 15px;
      gap: 8px;

      .timeline-card-header-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 8px;

        .timeline-card-header {
          display: flex;
          flex-direction: column;

          .timeline-card-header-title {
            display: flex;
            flex-direction: row;
            gap: 10px;
            align-items: center;

            app-icon {
              padding: 4px;
              border-radius: 5px;
              background-color: var(--primary);
              align-items: center;
              display: flex;
            }

            .timeline-event-name {
              font-size: 20px;
              color: var(--primary);
              font-weight: 100;
            }
          }

          .timeline-event-time {
            font-size: 16px;
            color: var(--secondary);
            font-weight: 100;
          }
        }

        .card-header {
          .links {
            display: flex;
            flex-direction: row;
            gap: 8px;
          }
        }
      }

      .timeline-event-description {
        font-weight: 100;
        padding-bottom: 5px;
      }
    }

    .card-image-container {
      min-height: 100%;
      max-width: 30%;
      min-width: 30%;
      mask-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 1) 50%,
        transparent 100%
      );
      background-color: var(--secondary);
      border-radius: 0px 5px 5px 0px;

      .card-image {
        border-radius: 0px 5px 5px 0px;
        min-height: 100%;
        min-width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 0.85;
      }
    }

    &:hover {
      .card-image-container {
        background-color: transparent;

        .card-image {
          -webkit-filter: none;
          filter: none;
          opacity: 1;
        }
      }
    }
  }
}

.show-pdf {
  display: none;
}

.show-app {
  display: auto;
}

@media print {
  .show-pdf {
    display: block;
  }

  .show-app {
    display: none;
  }
}

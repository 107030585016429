.icon-button {
  background: var(--primary);
  border-radius: 5px;
  width: 24px;
  height: 24px;
  padding: 10px;
  position: relative;

  .iconify {
    font-size: 24px;
    color: var(--primary-contrast) !important;
  }

  &:hover {
    background: var(--secondary);
    border-radius: 5px;
    cursor: pointer;

    .iconify {
      color: var(--dark);
    }
  }

  &.small {
    width: 24px;
    height: 24px;

    .iconify {
      font-size: 24px;
    }
  }

  &.x-small {
    width: 16px;
    height: 16px;

    .iconify {
      font-size: 20px;
      margin-left: -2px;
      margin-top: -2px;
    }
  }

  &.hidden {
    visibility: hidden;
  }
}

.clickable-container {
  cursor: pointer;

  &:hover {
    .subsection-name {
      color: var(--secondary) !important;
    }
    .subsection-image {
      background-color: var(--secondary) !important;
    }
  }
}

.button-container {
  border-radius: 5px;
  background-color: var(--primary);
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: var(--secondary);
  }

  &.small {
    height: 24px;
    width: 24px;

    app-icon,
    .button-image {
      width: 20px;
      height: 20px;
    }
  }

  &.medium {
    height: 36px;
    width: 36px;

    app-icon,
    .button-image {
      width: 24px;
      height: 24px;
    }
  }
}

.text-button {
  background: var(--primary);
  border-radius: 5px;
  padding: 6px;
  position: relative;
  background-color: var(--primary);
  box-shadow: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--secondary);
    border-radius: 5px;
    cursor: pointer;

    span {
      color: var(--dark);
    }
  }
}

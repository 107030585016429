.mat-toolbar {
  height: 50px !important;
  color: var(--primary) !important;
  background: var(--dark-700) !important;
}

.drawnbro-logo-container {
  background: var(--primary);
}

.drawnbro:hover {
  .drawnbro-logo-container {
    background: var(--secondary) !important;
  }
  span {
    color: var(--secondary) !important;
  }
}

.drawnbro {
  cursor: pointer;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */

  text-align: center;
  display: flex;
  align-items: center;

  .drawnbro-logo-container {
    height: 36px;
    width: 36px;
    border-radius: 5px;
    margin-right: 10px;

    .drawnbro-logo {
      width: 24px;
      height: 24px;
      margin-top: 6px;
    }
  }

  span {
    margin-top: 2px;
    font-size: 20px;
    font-weight: 500;
    color: var(--primary);
    margin-bottom: 3px;
  }
}

.social-media-icons {
  bottom: 25px;
  display: flex;
  flex-direction: row;
}

.social-media-icons > div {
  padding: 10px;
  margin: 5px;

  .iconify {
    position: absolute;
  }
}

/* You can add global styles to this file, and also import other style files */

@import "./assets/styles/theme.scss";

@import "./assets/styles/components/button.scss";
@import "./assets/styles/components/cv.scss";
@import "./assets/styles/components/scroll-bar.scss";
@import "./assets/styles/components/toolbar.scss";
@import "./assets/styles/components/footer.scss";
@import "./assets/styles/components/pdf.scss";
@import "./assets/styles/components/terms.scss";
@import "./assets/styles/components/timeline-card.scss";

html,
body {
  height: 100%;
  margin: 0px;
  background: mat-color($dark, default);
  color: var(--text);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Ubuntu, "Helvetica Neue", sans-serif;
}

a {
  color: inherit;
  text-decoration: none; /* no underline */
}

li {
  list-style-type: none;
}

.col {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  flex-direction: row;
}

.hidden {
  visibility: hidden;
}

.display-none {
  display: none;
}

.cdk-overlay-container .mdc-tooltip {
  --mdc-plain-tooltip-container-color: var(--dark-700);
  --mdc-plain-tooltip-supporting-text-color: var(--secondary);

  .mdc-tooltip__surface {
    font-family: Ubuntu, "Helvetica Neue", sans-serif;
    font-weight: normal;
    font-size: 12px;
  }

  &:hover {
    display: none;
  }
}

body::-webkit-scrollbar {
  width: 10px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--dark-700);
}

body::-webkit-scrollbar-track {
  background: var(--dark-700);
}

body::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--dark-700);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  filter: brightness(20%);
}

// Experience Section
.section-header {
  display: flex;
  flex-direction: row;
  border-top: 1px solid var(--text);
  border-bottom: 1px solid var(--text);
  padding: 10px;

  .section-header-title {
    font-size: 16px;
    line-height: 16px;
  }
}

.section-body {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;

  .item-card {
    width: inherit;

    .subsection-header {
      gap: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      .subsection-image {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        background-color: var(--primary);
        // NOTE: ensures the style is printed even without the print background graphics enabled
        // only works on chrome
        -webkit-print-color-adjust: exact;

        img {
          border-radius: 3px;
          height: 28px;
          width: 28px;
        }
      }

      .position-details {
        .position-name {
          font-size: 16px;
          line-height: 16px;
          color: var(--primary);
        }

        .position-dates {
          margin-top: 5px;
          font-size: 13px;
          line-height: 13px;
          font-weight: 300;
          color: var(--secondary);
        }
      }

      .subsection-name {
        font-size: 16px;
        line-height: 16px;
        color: var(--primary);
      }
    }

    .subsection-body {
      display: flex;
      flex-direction: column;
      gap: 5px;

      .subsection-description {
        max-width: 100%;
        font-size: 12px;
        font-weight: 100;
        margin-top: 5px;
        text-align: justify;
        color: var(--text-100);
      }

      .skills-list {
        font-size: 12px;
        font-weight: 100;
        font-style: italic;
        color: var(--text);
        text-align: justify;

        &.management-skills {
          color: var(--text);
        }
      }

      &.divide {
        margin-top: 8px;
        gap: 8px;
      }

      .skills-section {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
    }

    .project-header {
      justify-content: space-between;
    }

    .project-container {
      gap: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
    }

    .projects-list-container {
      &.degree-projects {
        .subsection-header {
          &.project-header {
            font-size: 12px;
            font-weight: 100;
            margin-top: 8px;
            margin-bottom: 5px;
            color: var(--text-100);
          }
        }

        .project-container {
          margin-left: 5px;

          .subsection-name {
            font-size: 12px;
            font-weight: 100;
            font-style: italic;
            margin-bottom: 5px;
          }
        }
      }
    }

    .documents-list-container {
      &.degree-documents {
        .subsection-header {
          &.document-header {
            font-size: 12px;
            font-weight: 100;
            margin-top: 5px;
            margin-bottom: 5px;
            color: var(--text-100);
          }
        }

        .document-container {
          margin-left: 5px;

          .subsection-name {
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .project-links {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}
